<template>
  <div class="red_invoice_me_send_view">
    <div class="content-select">
      <div class="select-content">
        <el-form ref="filterForm" :model="selectForm" inline label-width="110px">
          <el-row class="row-first">
            <el-col :span="8">
              <el-form-item label="购/销身份" prop="status">
                <el-select v-model="selectForm.redSource" placeholder="请选择">
                  <el-option label="销售方" value="seller"></el-option>
                  <el-option label="购买方" value="buyer"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="购方名称" prop="status">
                <el-input v-model="selectForm.buyerName" placeholder="请输入购方名称" clearable style="width: 220px"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销方名称" prop="status">
                <el-input v-model="selectForm.sellerName" placeholder="请输入销方名称" clearable style="width: 220px" />
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="发票状态" prop="status">
                <el-select v-model="selectForm.invoiceStatus" placeholder="操作状态" clearable style="width: 220px">
                  <el-option v-for="dict in invoiceStatus" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="确认状态" prop="status">
                <el-select v-model="selectForm.confirmStatus" placeholder="操作状态" clearable style="width: 220px">
                  <el-option v-for="dict in confirmStatus" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="确认日期" prop="loginTime">
                <el-date-picker v-model="selectForm.confirmTimeRange" style="width: 220px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row-first" style="margin-top: 15px">
            <el-col :span="8">
              <el-form-item label="" prop="loginTime">
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="loginTime">
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="handleQuery">查询</el-button>
              <el-button type="default" @click="resetQuery">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <el-table ref="multipleTable" :data="tableData" border stripe v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" style="width: 100%">
          <el-table-column type="selection" width="70" fixed="left" />
          <el-table-column type="index" label="序号" width="60" />
          <el-table-column prop="redSource" label="购销身份" :formatter="handleTableValueFormat" width="180" />
          <el-table-column prop="buyerName" label="购方名称" show-overflow-tooltip width="180" />
          <el-table-column prop="buyerTaxNo" show-overflow-tooltip label="购方税号" width="120" />
          <el-table-column prop="invoiceNo" show-overflow-tooltip label="对应蓝票全电号码" width="120" />
          <el-table-column prop="redNoticeNo" show-overflow-tooltip label="红字确认单编号" width="180" />
          <el-table-column prop="amount" label="金额" width="120" />
          <el-table-column prop="taxAmount" label="税额" width="120" />
          <el-table-column prop="sumAmount" label="价税合计" width="120" />
          <el-table-column prop="confirmStatus" label="确认状态" :formatter="handleTableValueFormat" width="120" />
          <el-table-column prop="confirmTime" show-overflow-tooltip label="确认日期" width="120" />
          <el-table-column prop="invoiceStatus" label="发票状态" :formatter="handleTableValueFormat" width="120" />
          <el-table-column prop="sellerName" label="销方名称" show-overflow-tooltip width="180" />
          <el-table-column prop="sellerTaxNo" show-overflow-tooltip label="销方税号" width="120" />
          <el-table-column prop="operate" label="操作" fixed="right" width="250">
            <template slot-scope="scope">
              <el-button type="text" style="padding: 0" @click="handleDetail(scope.row)">查看</el-button>
              <el-button type="text" style="padding: 0"  :disabled="(scope.row.confirmStatus != 'YQR' && scope.row.confirmStatus != 'WXQR') || (scope.row.invoiceStatus == 'YKJ')" @click="handleBillToRed(scope.row)">开票</el-button>
              <el-button type="text" style="padding: 0" :disabled="scope.row.confirmStatus != 'WQR'" @click="handleRedNoticeRepeal(scope.row)">撤销</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <!-- 分页组件 -->
        <el-pagination class="p-page" :current-page.sync="selectForm.page" :page-size="selectForm.size" :total="page.total" layout="total,sizes,prev,pager,next" :page-sizes="[15, 30, 50, 100]" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>
      <el-dialog append-to-body :visible.sync="noticeDetailVisible" width="1144px">
        <AboutMeInvoiceDetail :redNotice="redNoticeInvoice" :info="billingInvoice" />
      </el-dialog>
      <el-dialog append-to-body title="" width="420px" :visible.sync="billingTradeVisible">
        <div class="dialog-trade-generate" style="margin-top: -40px;">
          发票开具成功!
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button type="default" @click="billingTradeVisible = false">取消</el-button>
          <el-button type="primary" @click="billingTradeVisible = false">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { tableStyle, changeMethods } from '@/util/mixins';
import {
  getBillingRedNoticeDetails,
  getBillingRedNoticeSendMeDetails,
  getRedNoticeByPage,
  handleRedNoticeRepeal
} from '@/service/invoice-open';
import AboutMeInvoiceDetail from './components/AboutMeInvoiceDetail';
import { selectInvoice } from '@/service/sal/invoice';
import { handleElectricRedTicket } from '@/service/sal/invoice';

export default {
  name: 'RedInvoiceMeSendList',
  mixins: [tableStyle, changeMethods],
  components: {
    AboutMeInvoiceDetail
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      selectForm: {
        redSource: 'seller',
        sellerName: null,
        buyerName: null,
        invoiceStatus: '',
        confirmTimeRange: [],
        confirmStatus: 'WQR',
        page: 1,
        size: 15
      },
      page: {
        total: 0,
        size: 15,
        current: 1
      },
      invoiceStatus: [
        { label: '全部', value: '' },
        { label: '已开具', value: 'YKJ' },
        { label: '未开具', value: 'WKP' },
        { label: '开具中', value: 'KJZ' },
        { label: '开具失败', value: 'KJSB' }
      ],
      confirmStatus: [
        { label: '无需确认', value: 'WXQR' },
        { label: '已确认', value: 'YQR' },
        { label: '未确认', value: 'WQR' },
        { label: '已撤回', value: 'YCH' },
        { label: '已作废', value: 'YZF' }
      ],
      billingInvoice: {},
      redNoticeInvoice: {},
      noticeDetailVisible: false,
      billingTradeVisible: false
    };
  },

  created() {
    this.getList();
  },
  methods: {
    /**  */
    async getList() {
      this.tableLoading = true;
      // this.selectForm['redSource'] = 'seller';
      this.selectForm['startSource'] = 'MESEND';
      const { success, data } = await getRedNoticeByPage(this.selectForm);
      this.tableLoading = false;
      if (success) {
        this.page = data;
        this.tableData = data.records;
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.page.current = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.selectForm.redSource = 'seller';
      this.selectForm.invoiceStatus = '';
      this.selectForm.buyerName = null;
      this.selectForm.sellerName = null;
      this.selectForm.confirmTimeRange = [];
      this.selectForm.confirmStatus = 'WQR';
    },
    handleSizeChange(val) {
      this.selectForm.size = val;
      this.selectForm.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.selectForm.page = val;
      this.getList();
    },
    async handleBillToRed(row) {
      this.$confirm('发票开具, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        handleElectricRedTicket({id: row.id}).then(res => {
          this.tableLoading = false;
          if (res.success) {
            this.billingTradeVisible = true
          }
        }).catch(err => {
          this.toast('发票开具失败!', 'warning');
        });

      }).catch(() => {
        this.toast('取消发票开具!', 'warning');
      });
    },
    async handleDetail(row) {
      const { success, data } = await getBillingRedNoticeSendMeDetails(row.id);
      if (success) {
        this.redNoticeInvoice = data
        this.billingInvoice = { ...row, ...data };
        this.billingInvoice['redNoticeNo'] = row['redNoticeNo'];
        if (row.billingInvoiceId != 0) {
          this.billingInvoice['redSource'] = 'seller';
          this.billingInvoice['deductionType'] = '-1';
        }
        let sumAmount = this.billingInvoice.items.map((item) => Number(item.amount)).reduce((sum, amount) => sum + amount, 0);
        let sumTaxAmount = this.billingInvoice.items.map((item) => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0);
        let sum = Number(sumAmount + sumTaxAmount).toFixed(2);
        this.billingInvoice['xxSumAmount'] = sum;
        this.billingInvoice['dxSumAmount'] = this.handleSmallToCapitalize(sum);
        this.noticeDetailVisible = true;
      }
    },
    async handleRedNoticeRepeal(item) {
      this.$confirm('是否撤回此确认单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        handleRedNoticeRepeal({id: item.id}).then(res => {
          if (res.success) {
            this.toast('撤回成功!', 'success');
          }
        }).catch(err => {
          this.toast('撤回失败!', 'warning');
        });

      }).catch(() => {
      });
    },
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '购销身份':
          return '销售方';
        case '发票状态':
          var invoiceStatus = this.invoiceStatus.find((item) => item.value == row.invoiceStatus);
          if (invoiceStatus != null) {
            return invoiceStatus.label;
          }
          return row.invoiceStatus;
        case '确认状态':
          var confirmStatus = this.confirmStatus.find((item) => item.value == row.confirmStatus);
          if (confirmStatus != null) {
            return confirmStatus.label;
          }
          return row.confirmStatus;
        default:
          return value;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.red_invoice_me_send_view {
  .select-content {
    padding: 20px 0 20px 20px;
  }
}

.detail-row {
  text-align: left;
  margin-top: 30px;
  margin-left: 150px;

  .detail-row-info {
    display: flex;
  }
}
</style>
