<template>
  <div class="red_invoice_info_input_cmps">
    <div class="step_box">
      <!-- @click="handelerReturn" -->
      <i class="el-icon-back" @click="clickClose"></i>
      <el-steps :active="stepActive" align-center>
        <el-step title="选择票据" />
        <el-step title="信息确认" />
        <el-step title="提交成功" />
      </el-steps>
    </div>
    <template v-if="stepActive == 1">
      <el-form class="info_form_box" ref="filterForm" :model="form" inline label-width="130px">
        <el-row class="row-first">
          <el-col :span="8">
            <el-form-item required label="发票查询">
              <el-select v-model="form.billPlatform" :disabled="equipment != null && equipment.billingChannel == 'QD-XSD'" placeholder="请选择">
                <el-option label="本地数据" value="dotax"></el-option>
                <el-option label="查验接口" value="other"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item required label="购/销方选择">
              <el-select v-model="form.redSource" placeholder="请选择">
                <el-option label="销售方" value="seller"></el-option>
                <el-option label="购买方" value="buyer"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div class="full_invoice_number">
              <el-form-item  required label="全电发票号码" prop="invoiceNo" :rules="[{ required: true, message: '全电发票号码必填'}]">    
                <el-input v-model="form.invoiceNo" placeholder="请输入全电发票号码" style="width: 220px" />
              </el-form-item>
            </div>
            
          </el-col>
        </el-row>
        <el-row class="row-first">
          <el-col :span="8">
            <el-form-item required prop="orgId" label="当前组织"
                          :rules="[{ required: true, message: '当前组织必填'}]">
              <organization-select :model="form" @handleSelectAction="handleCurrentOrganizations" :is-add-all="false"
                                   allLabel="请选择组织">
              </organization-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :required="form.billPlatform == 'other'" prop="billingType" label="发票类型"
                          :rules="form.billPlatform == 'other' ? [{ required: true, message: '发票类型必填'}] : []">
              <el-select v-model="form.billingType" placeholder="请选择">
                <el-option v-for="billingType in billingTypes" :key="billingType.value" :label="billingType.label" :value="billingType.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :required="form.billPlatform == 'other'" prop="originalInvoicingDate" label="开票日期"
                          :rules="form.billPlatform == 'other' ? [{ required: true, message: '开票日期必填'}] : []">
              <el-date-picker v-model="form.originalInvoicingDate" style="width: 220px" value-format="yyyy-MM-dd" placeholder="选择开票日期" type="date" ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-first">
          <el-col :span="8">
            <el-form-item :required="form.billPlatform == 'other'" label="价税合计" prop="sumAmount"
                          :rules="form.billPlatform == 'other' ? [{ required: true, message: '价税合计必填'}] : []">
              <el-input v-model="form.sumAmount" onkeyup="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入价税合计" style="width: 220px" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-first">
          <div style="display: flex;justify-content:space-between ">
            <div>
              <!-- <div style="margin-left: 30px;font-size: 12px;color: red;">发票查询为"本地数据",购/销方选择,全电发票号码为必填项,其他为非必填;</div>
              <div style="margin-left: 30px;font-size: 12px;color: red;">发票查询为"查验接口",全部为必填项;</div> -->
            </div>
            <div style="padding-right: 30px;">
              <el-button type="primary" @click="handleQuery">查询</el-button>
            </div>
          </div>
        </el-row>
      </el-form>
      <el-table ref="multipleTable" :data="tableData" border stripe v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" style="width: 100%">
        <el-table-column type="index" label="序号" width="60" />
        <el-table-column label="购销身份" width="180">
          <template slot-scope="scope">
            {{form.redSource == 'seller' ? '销售方' : '购买方'}}
          </template>
        </el-table-column>
        <el-table-column prop="buyerName" show-overflow-tooltip label="购方名称" width="100"/>
        <el-table-column prop="buyerTaxNo" label="购方税号" show-overflow-tooltip width="180"/>
        <el-table-column prop="invoiceNo" show-overflow-tooltip label="蓝票全电发票号码" width="180" />
        <el-table-column prop="sumAmount" label="价税合计" width="120" />
        <el-table-column prop="invoiceTime" show-overflow-tooltip label="开票日期" />
        <el-table-column prop="operate" label="操作" fixed="right" width="250">
          <template slot-scope="scope">
            <el-button type="text" @click="handleInvoiceImage(scope.row)" style="padding: 0">预览票据</el-button>
            <el-button type="text" @click="handleInvoiceDetail(scope.row.id)" style="padding: 0">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-if="stepActive == 2">
      <div class="cus_desc_box">
        <el-descriptions title="已选票据" :column="2">
          <el-descriptions-item label="票面金额："
            ><span class="txt fs">{{ invoice.amount }}</span></el-descriptions-item
          >
          <el-descriptions-item label="全电发票号码："
            ><span class="txt fs">{{ invoice.invoiceNo }}</span></el-descriptions-item
          >
          <el-descriptions-item label="(销售方)纳税人名称："
            ><span class="txt">{{ invoice.sellerName }}</span></el-descriptions-item
          >
          <el-descriptions-item label="(销售方)统一社会信用代码/纳税人识别号："
            ><span class="txt">{{ invoice.sellerTaxNo }}</span></el-descriptions-item
          >
          <el-descriptions-item label="(购买方)纳税人名称："
            ><span class="txt">{{ invoice.buyerName }}</span></el-descriptions-item
          >
          <el-descriptions-item label="(购买方)统一社会信用代码/纳税人识别号："
            ><span class="txt">{{ invoice.buyerTaxNo }}</span></el-descriptions-item
          >
          <el-descriptions-item label="开票日期："
            ><span class="txt">{{ fmtOrderTime(invoice.invoiceTime) }}</span></el-descriptions-item
          >
          <el-descriptions-item label="入账状态："
          ><span class="txt">{{ fmtChargeAccountStatus(invoice.chargeAccountStatus) }}</span></el-descriptions-item
          >
        </el-descriptions>
      </div>
      <el-form class="info_form_box" ref="filterForm" :model="form" inline label-width="140px">
        <el-form-item label="开具红字发票原因" required>
          <el-select v-model="toredDes" @change="toredDesChange" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <!--          <el-button type="primary" @click="handleQuery">含税</el-button>-->
          <el-button @click="handleChangeTaxStatus" :type="typeByTaxStatus">{{ hadTax | fmtTaxStatus }} </el-button>
        </el-form-item>
      </el-form>
      <el-table ref="multipleTable"  height="270" :data="tableDataDetail" border stripe v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" style="width: 100%">
        <el-table-column type="index" label="序号" width="60" />
        <el-table-column label="项目名称" show-overflow-tooltip prop="merchandiseName" width="180"> </el-table-column>
        <el-table-column prop="taxClassCode" label="税收分类编码" show-overflow-tooltip width="180"> </el-table-column>
        <el-table-column prop="specification" label="规格型号" width="120" />
        <el-table-column prop="unit" label="单位" width="120" />
        <el-table-column prop="quantity" label="数量" width="120" />
        <el-table-column prop="price" width="120" v-if="hadTax" label="单价(含税)">
          <template scope="scope">
            {{ scope.row.price }}
          </template>
        </el-table-column>
        <el-table-column prop="price" width="120" v-else label="单价(不含税)">
          <template scope="scope">
            {{ scope.row.unTaxPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" width="120" v-if="hadTax" label="金额(含税)">
          <template scope="scope">
            {{ scope.row.sumAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" width="120" v-else label="金额(不含税)">
          <template scope="scope">
            {{ scope.row.amount }}
          </template>
        </el-table-column>
        <el-table-column prop="taxRate" label="税率/征收率" />
        <el-table-column prop="taxAmount" label="税额" />
        <el-table-column prop="operate" label="操作" v-if="equipment != null && equipment.billingChannel != 'QD-XSD'" fixed="right" width="250">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)" style="padding: 0">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="opt_box">
        <el-button @click="stepActive = 1">上一步</el-button>
        <el-button type="primary" @click="handleFullElecApplySubmit">提交</el-button>
      </div>
    </template>
    <template v-if="stepActive == 3">
      <div style="text-align: center; margin: 20px 0">
        <i class="el-icon-success" style="font-size: 80px; color: #1890ff"></i>
        <div>已提交</div>
      </div>
      <div class="cus_desc_box">
        <el-descriptions title="已选票据" :column="2">
          <el-descriptions-item label="票面金额："
            ><span class="txt fs">¥{{ invoice.amount }}</span></el-descriptions-item
          >
          <el-descriptions-item label="全电发票号码："
            ><span class="txt fs">{{ invoice.invoiceNo }}</span></el-descriptions-item
          >
          <el-descriptions-item label="(销售方)纳税人名称："
            ><span class="txt">{{ invoice.sellerName }}</span></el-descriptions-item
          >
          <el-descriptions-item label="(销售方)统一社会信用代码/纳税人识别号："
            ><span class="txt">{{ invoice.sellerTaxNo }}</span></el-descriptions-item
          >
          <el-descriptions-item label="(购买方)纳税人名称："
            ><span class="txt">{{ invoice.buyerName }}</span></el-descriptions-item
          >
          <el-descriptions-item label="(购买方)统一社会信用代码/纳税人识别号："
            ><spa class="txt">{{ invoice.buyerTaxNo }}</spa></el-descriptions-item
          >
          <el-descriptions-item label="开票日期："
            ><span class="txt">{{ fmtOrderTime(invoice.invoiceTime) }}</span></el-descriptions-item
          >
        </el-descriptions>
      </div>
      <div class="opt_box">
        <el-button @click="handleCloseApply">关闭申请</el-button>
        <el-button @click="handleDetail">查看确认单详情</el-button>
        <el-button @click="handleApplyAgain" type="primary">继续申请确认单</el-button>
      </div>
    </template>
    <!-- 票据影像弹窗 -->
    <el-image-viewer v-if="invoiceImageViewer" :url-list="invoiceImages" :on-close="handleImageViewerClose" style="z-index: 2020" />
    <el-dialog append-to-body :visible.sync="noticeDetailVisible" width="1144px">
      <AboutMeInvoiceDetail :redNotice="redNoticeInvoice" :info="billingInvoice" />
    </el-dialog>
    <el-dialog title="修改" width="800px" append-to-body
               :visible.sync="itemEditVisible">
      <div class="wait-invoice" style="margin-bottom: 30px">
        <span class="invoice-type">
          项目名称:
        </span>
        <el-input disabled v-model="redNoticeItem.merchandiseName"></el-input>
      </div>
      <div class="wait-invoice" style="margin-bottom: 30px">
        <span class="invoice-type">
          规格型号:
        </span>
        <el-input disabled v-model="redNoticeItem.specification" maxlength="128"></el-input>
      </div>
      <div class="wait-invoice" style="margin-bottom: 30px">
        <span class="invoice-type">
          商品单位:
        </span>
        <el-input disabled v-model="redNoticeItem.unit"  maxlength="25"></el-input>
      </div>
      <div class="wait-invoice" style="margin-bottom: 30px">
        <span class="invoice-type">
          商品数量:
        </span>
        <el-input :disabled="isEditQuantity" v-model="redNoticeItem.quantity" @blur="handleEditQuantityCalculate()" onkeyup="value=value.replace(/[^0-9.]/g,'')"  maxlength="20"></el-input>
      </div>
      <div class="wait-invoice" v-if="hadTax" style="margin-bottom: 30px">
        <span class="invoice-type">
          含税单价:
        </span>
        <el-input :disabled="isEditPrice" v-model="redNoticeItem.price" @blur="handleEditQuantityCalculate()" onkeyup="value=value.replace(/[^0-9.]/g,'')" maxlength="20"></el-input>
      </div>
      <div class="wait-invoice" v-else style="margin-bottom: 30px">
        <span class="invoice-type">
          未税单价:
        </span>
        <el-input :disabled="isEditPrice" v-model="redNoticeItem.unTaxPrice" @blur="handleEditQuantityCalculate()" onkeyup="value=value.replace(/[^0-9.]/g,'')" maxlength="20"></el-input>
      </div>
      <div class="wait-invoice" v-if="hadTax" style="margin-bottom: 30px">
        <span class="invoice-type">
          含税金额:
        </span>
        <el-input :disabled="isEditAmount" v-model="redNoticeItem.sumAmount" @blur="handleEditSumAmountCalculate()" onkeyup="value=value.replace(/[^0-9.]/g,'')" maxlength="20"></el-input>
      </div>
      <div class="wait-invoice" v-else style="margin-bottom: 30px">
        <span class="invoice-type">
          未税金额:
        </span>
        <el-input :disabled="isEditAmount" v-model="redNoticeItem.amount" @blur="handleEditUnTaxAmountCalculate()" onkeyup="value=value.replace(/[^0-9.]/g,'')" maxlength="20"></el-input>
      </div>
      <div class="wait-invoice" style="margin-bottom: 30px">
        <span class="invoice-type">
          税率/征收率:
        </span>
        <el-input disabled v-model="redNoticeItem.taxRate"  maxlength="20"></el-input>
      </div>
      <div class="wait-invoice" style="margin-bottom: 30px">
        <span class="invoice-type">
          商品税额:
        </span>
        <el-input disabled v-model="redNoticeItem.taxAmount"  maxlength="20"></el-input>
      </div>
      <div class="dialog-footer" slot="footer" style="margin-right: 5px">
        <el-button @click="itemEditVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditItem">提 交</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import OrganizationSelect from '@/components/widgets/OrganizationSelect'
import { tableStyle, changeMethods } from '@/util/mixins';
import { waitToRedInvoicePage, getInvoiceImage, selectInvoice, getInvoiceChargeAccountStatus } from '@/service/sal/invoice';
import {INVOICE_STATUS} from '@/content/invoice.js';
import { fullElecApplySubmit } from '@/service/invoice-open';
import {getInvoiceImageListById} from "@/service/invoice-center";
import AboutMeInvoiceDetail from './AboutMeInvoiceDetail';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  name: 'RedInvoiceInfoInput',
  mixins: [tableStyle, changeMethods],
  components: {ElImageViewer, AboutMeInvoiceDetail, OrganizationSelect},
  props: {
    show: Boolean,
    invoiceId: null,
    step: null,
    equipment: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    show(val) {
      this.visiable = val;
    },
    step: {
      handler: function (val) {
        this.stepActive = val;
      },
      immediate: true
    },
    invoiceId: {
      handler: function (val) {
        val && this.handleInvoiceDetail(val);
      },
      immediate: true
    }
  },
  data() {
    return {
      buleInvoiceId: null,
      form: {
        invoiceNo: null,
        redSource: 'seller',
        billingTypes: ['QDZP', 'QDPP'],
        billPlatform: 'dotax',
        billingType: 'QDZP',
        originalInvoicingDate: '',
        sumAmount: undefined,
        orgId: localStorage.getItem('orgId') ? Number(localStorage.getItem('orgId')) : ''
      },
      toredDes: '',
      options: [
        {
          value: '01',
          label: '开票有误'
        },
        {
          value: '02',
          label: '销货退回'
        },
        {
          value: '03',
          label: '服务中止'
        },
        {
          value: '04',
          label: '销售折让'
        }
      ],
      stepActive: 1,
      visiable: true,
      active: 0,
      tableData: [],
      tableDataDetail: [],
      invoice: {},
      hadTax: true, // 含税状态
      tableLoading: false,
      /* 票据影像数据区 */
      invoiceImageViewer: false,
      invoiceImages: [],
      noticeDetailVisible: false,
      billingInvoice: {},
      redNoticeInvoice: {},
      billingTypes: [
        {
          value: '',
          label: '请选择'
        },
        {
          value: 'QDPP',
          label: '电子发票(普通发票)'
        },
        {
          value: 'QDZP',
          label: '电子发票(增值税专用发票)'
        },
      ],
      isEditQuantity: false,
      isEditAmount: false,
      isEditPrice: false,
      itemEditVisible: false,
      redNoticeItem: {},
      invoiceStatuses1: this.$store.state.enums.invoiceStatus
    };
  },
  filters: {
    fmtTaxStatus(val) {
      return val ? '含税' : '不含税';
    }
  },
  computed: {
    // 含税按钮状态
    typeByTaxStatus() {
      return this.hadTax ? 'default' : 'primary';
    }
  },
  created() {},
  methods: {
    clickClose(){
      this.$emit('handlerClone',false)
    },
    handleQuery() {
      if(this.form.invoiceNo == null || this.form.invoiceNo == ''){
        this.toast('请输入全电发票号码！', 'warning');
        return;
      }
      if(this.form.orgId == null || this.form.orgId == ''){
        this.toast('请选择当前组织！', 'warning');
        return;
      }
      if(this.form.billPlatform == 'other'){
        if(this.form.billingType == null || this.form.billingType == ''){
          this.toast('请选择发票类型！', 'warning');
          return;
        }
        if(this.form.originalInvoicingDate == null || this.form.originalInvoicingDate == ''){
          this.toast('请选择开票日期！', 'warning');
          return;
        }
        if(this.form.sumAmount == null || this.form.billingType == ''){
          this.toast('请输入价税合计！', 'warning');
          return;
        }
      }
      this.getList();
    },
    async getList() {
      const { success, data } = await waitToRedInvoicePage(this.form);
      this.tableLoading = false;
      if (success) {
        if(data != null){
          this.tableData = [data];
        }else{
          this.tableData = [];
        }
      }
    },
    handleCloseApply() {
      this.$emit('handleCloseApply')
    },
    handleApplyAgain() {
      this.form.invoiceNo = '';
      this.tableData = [];
      this.stepActive = 1
    },
    //修改明细值
    handleEdit(row) {
      this.itemEditVisible = true
      this.redNoticeItem = row
      switch (this.toredDes){
        case "01":
          this.isEditQuantity = true;
          this.isEditPrice = true;
          this.isEditAmount = true;
          break;
        case "02":
          this.isEditQuantity = false;
          this.isEditPrice = true;
          this.isEditAmount = true;
          break;
        case "03":
          this.isEditQuantity = false;
          this.isEditPrice = true;
          this.isEditAmount = false;
          break;
        case "04":
          this.isEditQuantity = true;
          this.isEditPrice = true;
          this.isEditAmount = false;
          break;
        default:
          this.isEditQuantity = true;
          this.isEditPrice = true;
          this.isEditAmount = true;
          break;
      }
    },
    toredDesChange(row) {
      if(row == '3'){
        this.tableDataDetail.forEach(item => {
          let firstChar = item.taxClassCode.charAt(0);
          if(firstChar == '1' || firstChar == '2'){
            this.toast('商品税收分类编码以1、2开头的，冲红原因不允许选择"服务中止"！', 'warning');
            this.toredDes = undefined
            return;
          }
        })
      }else if(row == '2'){
        this.tableDataDetail.forEach(item => {
          let firstChar = item.taxClassCode.charAt(0);
          if(firstChar == '3'){
            this.toast('商品税收分类编码以 3 开头的冲红原因不允许选择"销货退回"', 'warning');
            this.toredDes = undefined
            return;
          }
        })
      }
    },
    //修改数量
    handleEditQuantityCalculate() {
      // 价税合计
      let sumAmount = Number(Number(this.redNoticeItem.quantity).toFixed(8)) * Number(Number(this.redNoticeItem.price).toFixed(8));
      sumAmount = Number(Number(sumAmount).toFixed(2));
      let amount = sumAmount / (Number(1 + Number(this.redNoticeItem.taxRate)).toFixed(8))
      amount = Number(amount).toFixed(2);
      let taxAmount = sumAmount - amount;
      this.redNoticeItem.sumAmount = sumAmount;
      this.redNoticeItem.amount = amount;
      this.redNoticeItem.taxAmount = taxAmount.toFixed(2);
    },
    //修改未税金额
    handleEditUnTaxAmountCalculate() {
      let amount =  Number(Number(this.redNoticeItem.amount).toFixed(8))
      let taxAmount = amount * Number(Number(this.redNoticeItem.taxRate).toFixed(8));
      taxAmount = Number(Number(taxAmount).toFixed(2));
      let sumAmount = Number(Number(amount).toFixed(2)) + taxAmount;
      this.redNoticeItem.sumAmount = sumAmount;
      this.redNoticeItem.amount = amount;
      this.redNoticeItem.taxAmount = Number(taxAmount).toFixed(2);
      let quantity = sumAmount /  this.redNoticeItem.price
      this.redNoticeItem.quantity = Number(quantity).toFixed(8)
    },
    //修改含税金额
    handleEditSumAmountCalculate() {
      let sumAmount =  Number(Number(this.redNoticeItem.sumAmount).toFixed(2))
      let amount = Number(sumAmount / (Number(1 + Number(this.redNoticeItem.taxRate)).toFixed(8))).toFixed(2)
      let taxAmount =  sumAmount - Number(amount)
      this.redNoticeItem.sumAmount = sumAmount;
      this.redNoticeItem.amount = amount;
      this.redNoticeItem.taxAmount = Number(taxAmount).toFixed(2);
      let quantity = sumAmount /  this.redNoticeItem.price
      this.redNoticeItem.quantity = Number(quantity).toFixed(8)
    },
    //提交更改明细
    submitEditItem() {
      let amount = 0;
      let taxAmount = 0;
      let sumAmount = 0;
      let quantity = 0;
      this.tableDataDetail.forEach(item=>{
        amount = Number(amount) + Number(Number(item.amount).toFixed(8));
        taxAmount = Number(taxAmount) + Number(Number(item.taxAmount).toFixed(8));
        sumAmount = Number(sumAmount) + Number(Number(item.sumAmount).toFixed(8));
        quantity = Number(quantity) + Number(Number(item.quantity).toFixed(8));
      });
      this.invoice.sumAmount = Number(sumAmount).toFixed(2);
      this.invoice.taxAmount = Number(taxAmount).toFixed(2);
      this.invoice.amount = this.invoice.sumAmount - this.invoice.taxAmount;
      this.invoice.quantity = quantity;
      this.itemEditVisible = false;
    },
    async handleInvoiceDetail(id) {
      if(id > 0) {
        if(this.tableData[0].invoiceStatus != 'YKJ'){
           let invoiceStatus = this.$store.state.enums['InvoiceStatus'].find(i => i.value == this.tableData[0].invoiceStatus);
           invoiceStatus = invoiceStatus == 'undefined' ? this.tableData[0].invoiceStatus : invoiceStatus.label;
           this.toast('只能选择发票状态为已开具的发票,当前发票状态:' + invoiceStatus, 'warning');
          return;
        }
        if(this.tableData[0].sumAmount < 0){
          this.toast('只能选择蓝字发票冲红', 'warning');
          return;
        }
        const {success, data} = await selectInvoice(id);
        if (success) {
          this.invoice = data;
          this.invoice['redSource'] = this.form.redSource;
          this.tableDataDetail = data.items;
          this.stepActive = 2;
        }
      }else{//查验接口获取的发票
        this.invoice = this.tableData[0];
        this.invoice['redSource'] = this.form.redSource;
        this.tableDataDetail = this.tableData[0].items;
        let params = {};
        params['invoiceNo'] = this.invoice.invoiceNo
        params['originalInvoicingDate'] = this.invoice.invoiceTime
        params['sumAmount'] = this.invoice.sumAmount
        params['redSource'] = this.invoice.redSource
        params['billingType'] = this.invoice.billingType
        params['sellerTaxNo'] = this.invoice.sellerTaxNo
        params['buyerTaxNo'] = this.invoice.buyerTaxNo
        params['orgId'] = this.invoice.orgId
        const { success, data } = await getInvoiceChargeAccountStatus(params);
        if (success) {
          this.invoice['chargeAccountStatus'] = data.chargeAccountStatus
        }
        this.stepActive = 2;

      }
    },
    async handleDetail(row) {

      this.noticeDetailVisible = false;
      this.redNoticeInvoice = this.invoice
      this.redNoticeInvoice['applyRemark'] = this.toredDes;
      this.billingInvoice['items'] = this.tableDataDetail;
      this.billingInvoice['invoiceNo'] = this.invoice.invoiceNo;
      this.billingInvoice['redNoticeNo'] = this.invoice['redNoticeNo'];
      if (row.billingInvoiceId != 0) {
        this.billingInvoice['redSource'] = 'seller';
        this.billingInvoice['deductionType'] = '-1';
      }
      let sumAmount = this.billingInvoice.items.map((item) => Number(item.amount)).reduce((sum, amount) => sum + amount, 0);
      let sumTaxAmount = this.billingInvoice.items.map((item) => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0);
      let sum = Number(sumAmount + sumTaxAmount).toFixed(2);
      this.billingInvoice['xxSumAmount'] = sum;
      this.billingInvoice['dxSumAmount'] = this.handleSmallToCapitalize(sum);
      this.noticeDetailVisible = true;
    },
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImageViewer = false;
      this.invoiceImages = [];
    },
    /* 发票影像 */
    handleInvoiceImage(invoice, isLook = true) {
      invoice.loading = true;
      getInvoiceImage(invoice.id).then((res) => {
        if (res.success) {
          if (isLook) {
            this.invoiceImageViewer = true;
          }
          this.imgPre = res.data[0];
          this.invoiceImages = [res.data];
        }
        invoice.loading = false;
      });
    },
    async handleFullElecApplySubmit() {
      if(this.invoice.chargeAccountStatus != '02' && this.invoice.redSource == 'buyer'){
        this.toast('当前发票未入账,购方不可以申请红字确认单！', 'warning');
        return;
      }
      if (this.toredDes == '' || this.toredDes == null) {
        this.toast('请选择冲红原因！', 'warning');
        return;
      }
      this.invoice['toRedRemark'] = this.toredDes;
      const { success, data } = await fullElecApplySubmit(this.invoice);
      this.tableLoading = false;
      if (success) {
        this.page = data;
        this.tableData = data.records;
        this.stepActive = 3;
      }
    },
    //获取当前组织列表
    handleCurrentOrganizations (model) {
      this.form.orgId = model.orgId
      console.log(model.orgId);
    },
    /** 重置按钮操作 */
    resetQuery() {},
    formatReciprocalName(row) {
      if (this.form.redSource == 'seller') {
        return row.buyerName;
      } else {
        return row.sellerName;
      }
    },
    fmtOrderTime(val) {
      return val && val.substring(0, 11);
    },
    fmtChargeAccountStatus(val) {
      switch(val){
        case '02':
          return '已入账';
        case '03':
          return '已入账撤销';
        case '01':
        default:
          return '未入账';
      }
    },
    formatReciprocalTaxNo(row) {
      if (this.form.redSource == 'seller') {
        return row.buyerTaxNo;
      } else {
        return row.sellerTaxNo;
      }
    },
    // 切换明细含税状态
    handleChangeTaxStatus() {
      this.hadTdFocus = false;
      this.hadTax = !this.hadTax;
    }
  }
};
</script>

<style lang="scss" scoped>
.red_invoice_info_input_cmps {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
  padding: 0 24px;
  height: calc(100vh - 120px);
  .step_box {
    margin: 50px 0 50px 0;
  }
  .info_form_box {
    margin: 20px 0;
  }
  .opt_box {
    text-align: center;
    margin: 40px 0;
  }
}
.detail-row {
  text-align: left;
  margin-top: 30px;
  margin-left: 150px;
}
</style>
<style lang="scss">
.cus_desc_box {
  background: #f5f7fa;
  width: 78%;
  margin: 0 auto;
  padding: 20px;
  .el-descriptions {
    .el-descriptions__body {
      background: #f5f7fa !important;
      .el-descriptions__table {
        background: #f5f7fa !important;
        color: #666;
        .txt {
          color: #333;
          font-size: 14px;
          &.fs {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.el-icon-back {
  font-size: 20px;
  position: absolute;
  top: 70px;
  pointer-events: auto;
  z-index: 1000;
}
.full_invoice_number{
  .el-form-item__error {
    top: 30px;
  }
 }
</style>
